<template>
  <ion-page>
    <Header
      @showSearch="(v) => showSearch = v"
    />

    <ion-content
      v-if="!showSearch"
      class="ion-padding-start ion-padding-end pt-5"
    >
      <ion-grid>
        <ion-row>
          <ion-col
            size="12"
            size-md="3"
          >
            <div class="mb-16-lg tablet-centered">
              <CompanyDetails
                :company-details="companyData"
                :only-name=true
              />
              <div
                class="company-details-footer"
              >
                <AppTooltip
                  :label="$t('onboardingTooltips.followCompanyBtn')"
                  position="is-bottom"
                  wrapper-class="is-size is-multiline"
                  @click:label="$emit('click:tooltip:label')"
                >
                  <FollowButton :company-id="companyData.id"/>
                </AppTooltip>
                <ShareButton
                  :text="companyData.name"
                  :company-id="companyData.id"
                />
              </div>
            </div>
            <section
              v-if="companyData.description"
              class="my-4 tablet-centered"
            >
              <p
                v-if="isReadMore"
                class="about"
              > {{ companyData.description }} </p>
              <p
                v-else
                class="about"
              > {{ shortDescription }} </p>
              <p
                v-if="hasReadMore"
                class="fz-12 fw-400 ion-text-right pointer"
                @click="isReadMore = !isReadMore"
              >
                {{ readMoreLabel }}
              </p>
            </section>
          </ion-col>
          <ion-col
            size="12"
            size-md="8"
            offset-md="1"
          >
            <section
              v-if="companyReleases.length"
            >
              <h1 class="mb-4"> {{ $t('companyPage.new') }} </h1>
              <ion-slides
                :options="{ slidesPerView: 'auto', slideShadows: false}"
                :key="`${companyData.id}-releases`"
              >
                <ion-slide
                  v-for="(data, i) in companyReleases"
                  :key="`releases-${i}`"
                  class="ion-justify-content-start"
                >
                  <FavouriteEpisode
                    :item="formattedPodcastData(data)"
                    @click="openPodcast(data.course_id, data.id)"
                  />
                </ion-slide>
              </ion-slides>
            </section>

            <section v-if="library.length">
              <h1 class="my-4"> {{ $t('companyPage.series') }} </h1>
              <ion-slides
                :options="{ slidesPerView: 'auto', slideShadows: false}"
                :key="`${companyData.id}-series`"
              >
                <ion-slide
                  v-for="(data, i) in library"
                  :key="`popular-${i}`"
                  class="ion-justify-content-start slide-lg"
                >
                  <FavouriteSeries
                    :item="data"
                    @click="$router.push(`/course/${data.id}`)"
                  />
                </ion-slide>
              </ion-slides>
            </section>

            <section
              v-if="companyPopular.length"
            >
              <h1 class="my-4"> {{ $t('companyPage.popular') }} </h1>
              <ion-slides
                :options="{ slidesPerView: 'auto', slideShadows: false}"
                :key="`${companyData.id}-popular`"
              >
                <ion-slide
                  v-for="(data, i) in companyPopular"
                  :key="`popular-${i}`"
                  class="ion-justify-content-start"
                >
                  <FavouriteEpisode
                    :item="formattedPodcastData(data)"
                    @click="openPodcast(data.course_id, data.id)"
                  />
                </ion-slide>
              </ion-slides>
            </section>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineEmits, computed, ref, watch  } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import CompanyDetails from '@/components/common/CompanyDetails.vue';
import FavouriteSeries from '@/components/following/FavouriteSeries.vue';
import FavouriteEpisode from '@/components/following/FavouriteEpisode.vue';
import Header from '@/components/common/Header';
import FollowButton from '@/components/common/FollowButton.vue';
import AppTooltip from '@/components/common/AppTooltip';
import ShareButton from '@/components/common/ShareButton.vue';

import {
  IonContent,
  IonPage,
  IonSlides,
  IonSlide,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';

const route = useRoute();
const { t } = useI18n();
const store = useStore();
const showSearch = ref(false);

const emit = defineEmits(['click:tooltip:label']);

const fetchAllData = () => {
  store.commit('company/resetState');
  store.dispatch('company/setCompanyDetails', route.params.companyId);
  store.dispatch('company/setCompanyPopular', route.params.companyId);
  store.dispatch('company/setCompanyReleases', route.params.companyId);
  store.dispatch('company/setCompanyLibrary', route.params.companyId);
};

fetchAllData();

const companyData = computed(() => store.getters['company/getCompanyData']);
const companyPopular = computed(() => store.getters['company/getCompanyPopular']);
const companyReleases = computed(() => store.getters['company/getCompanyReleases']);

const openPlayer = (id, course) => {
  const startAt = course.streams.findIndex((stream) => stream.id === id);
  store.dispatch('player/openPlayer', {
    course,
    company: course.company,
    playlist: course.streams,
    startAt,
  });
};

const openPodcast = (courseId, podcastId) => {
  store.dispatch('course/setCourseData', courseId).then((course) => {
    openPlayer(podcastId, course);
  });
};

const formattedPodcastData = (data) =>
  Object.assign({}, data, {
    course_media: data.course.media,
    company_name: data.company_name ? data.company_name : companyData.value?.name,
  });

const library = computed(() => store.getters['company/getCompanyLibrary']);

const maxLengthText = 234;
const isReadMore = ref(false);
const hasReadMore = computed(() => companyData.value?.description?.length > maxLengthText);
const readMoreLabel = computed(() => (isReadMore.value ? t('podcast.readLess') : t('podcast.readMore')));
const shortDescription = computed(() => companyData.value?.description?.substr(0, maxLengthText) ?? '');

watch(
  () => route.params.companyId,
  (newValue) => {
    if (newValue) {
      fetchAllData();
    }
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
  ion-slide {
    max-width: 200px !important;
    margin-right: 14px;
    cursor: pointer;
    &.slide-lg{
      max-width: 230px !important;
    }
  }

  .about {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
  .company-details-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
</style>
