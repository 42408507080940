<template>
  <div class="pointer">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col
          v-if="props.showImg"
          size="auto"
          class="ion-margin-end"
        >
          <AppImg
            :img-src="banner"
            img-alt="banner"
            class="img-wrap"
            border-radius="10px"
          />
        </ion-col>
        <ion-col>
          <div
            class="is-flex ion-align-items-center h-100"
            :class="{'divider': showDivider}"
          >
            <p class="fw-500">
              {{ props.podcastData.title || props.podcastData.stream_title }}
            </p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script setup>

import {
  CATEGORY_GENERAL_MEDIA,
  CATEGORY_BANNER_MEDIA,
  CATEGORY_STREAM_STILL,
  CATEGORY_PODCAST_IMG } from '@/config/constants';

const PRIOR_IMAGES = [
  CATEGORY_PODCAST_IMG,
  CATEGORY_STREAM_STILL,
  CATEGORY_BANNER_MEDIA,
  CATEGORY_GENERAL_MEDIA,
];

import {
  IonGrid,
  IonCol,
  IonRow,
} from '@ionic/vue';

import { computed, defineProps, defineEmits } from 'vue';

import { getSmallestSizeImage } from "@/helpers/functions";
import AppImg from '@/components/common/AppImg.vue';

const props = defineProps({
  podcastData: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  courseMedia: {
    type: Array,
    default: () => ([]),
  },
  showImg: {
    type: Boolean,
    default: true,
  },
  showDivider: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:favorites']);

const banner = computed(() => {
  const validStreamImages = props.podcastData.media?.filter(media => PRIOR_IMAGES.includes(media.category)) || [];
  validStreamImages.sort((a,b) => b.category - a.category);
  const streamImg = getSmallestSizeImage(validStreamImages)?.url ?? null;
  const courseImg = getSmallestSizeImage(props.courseMedia)?.url ?? null;
  return streamImg || courseImg;
} );
</script>

<style scoped lang="scss">
  @import "src/theme/custom";

  .img-wrap {
    height: 100px;
    width: 100px;

    &:deep(img) {
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.5rem;

    @media (max-width: $tablet-breakpoint) {
      font-size: 14px;
    }
  }

  .divider {
    padding: 10px 0;
    border-bottom: solid 1px white ;
  }
</style>
