<template>
  <ion-page :class="{ 'disabled-clicks': loading }">
    <ion-header
      v-if="isLogged"
      translucent
    >
      <ion-toolbar>
        <ion-buttons>
          <IonBackButton
            text=""
            default-href="/main/home"
          />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <AppHeaderDownloadApp />

    <ion-content>
      <ion-grid>
        <ion-row class="content-wrap ion-align-items-center">
          <ion-col
            size="4"
            class="is-flex is-justify-content-center"
          >
            <AppImg
              :img-src="banner"
              img-alt="banner"
              class="img-wrap"
              border-radius="20px"
            />
          </ion-col>

          <ion-col
            size="7"
            offset="1"
            offset-md="0"
          >
            <h1>{{ courseData.course_title }}</h1>

            <div class="is-flex ion-align-items-center mb-5">
              <h2
                class="pointer"
                @click="openCompanyDetailsPage"
              >{{ courseData.company.name }}</h2>
              <FollowButton
                :company-id="courseData.company.id"
                class="ml-5"
              />
            </div>

            <div class="is-flex ion-justify-content-end content-wrap__actions">
              <ShareButton
                class="mr-3"
                :text="courseData.course_title"
                :course-id="courseData.id"
              />
              <FavouriteButton
                :course-id="courseData.id"
                :entity-type="courseData.entity_type"
              />
            </div>

            <template v-if="!isMobileScreen">
              <h3>{{ t('podcast.information') }}</h3>
              <p>{{ courseData.course_introduction }}</p>
            </template>
          </ion-col>

          <ion-col
            size="12"
            size-md="7"
            offset-md="4"
          >
            <div class="content-wrap__podcasts mt-10">
              <ion-accordion-group
                value="ion-accordion-0"
                multiple
              >
                <ion-accordion
                  v-for="(child, index) in courseData.children"
                  :key="index"
                  :value="`ion-accordion-${index}`"
                  class="ion-margin-bottom"
                >
                  <ion-item
                    slot="header"
                    color="dark"
                  >
                    <ion-label class="content-wrap__podcasts__title">{{ child.title || child.course_title }}</ion-label>
                  </ion-item>

                  <div
                    class="ion-padding"
                    slot="content"
                  >
                    <!-- Lazy Load Episodes -->
                    <template
                      v-for="(episode, i) in visibleEpisodes[child.id]"
                      :key="i"
                    >
                      <SinglePodcastMinimized
                        v-for="(stream, j) in episode.streams"
                        :key="`podcast${j}`"
                        :podcast-data="stream"
                        :course-media="episode.media"
                        :show-img="!isMobileScreen"
                        :show-divider="isMobileScreen"
                        class="mb-5"
                        @click="openPlayer(stream, episode, courseData)"
                      />
                    </template>

                    <!-- Show message if episode has no streams -->
                    <ion-label
                      v-if="!visibleEpisodes[child.id]?.some(e => e.streams.length)"
                      class="content-wrap__podcasts__label"
                    >{{ $t('podcast.noStreams') }}</ion-label>
                  </div>
                </ion-accordion>

                <!-- Infinite Scroll for Episodes -->
                <ion-infinite-scroll @ionInfinite="loadMoreEpisodes">
                  <ion-infinite-scroll-content loading-text="Loading more episodes..."/>
                </ion-infinite-scroll>
              </ion-accordion-group>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n/index';

import SinglePodcastMinimized from '@/components/common/SinglePodcastMinimized.vue';
import AppHeaderDownloadApp from "@/components/common/AppHeaderDownloadApp";
import AppImg from '@/components/common/AppImg.vue';
import ShareButton from '@/components/common/ShareButton.vue';
import FavouriteButton from '@/components/common/FavouriteButton.vue';
import FollowButton from '@/components/common/FollowButton.vue';

import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonGrid,
  IonCol,
  IonRow,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue';

import {
  TRACKING_PUBLIC_COURSE_VIEW,
  TRACKING_COURSE_VIEW,
  TRACKING_MAIL_CONTENT_CLICK,
  CATEGORY_PODCAST_AUDIO,
} from "@/config/constants";

import useTracking from "@/composables/useTracking";
import useDeviceScreen from "@/composables/useDeviceScreen";
import { getSmallestSizeImage } from "@/helpers/functions";

const store = useStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const { isMobileScreen } = useDeviceScreen();
const { trackThis } = useTracking();

const isLogged = computed(() => store.getters['authentication/isLogged']);
const courseData = computed(() => store.getters['course/getCourseData']);

const loading = ref(true);
const episodesPerPage = 5; // Load episodes in chunks
const visibleEpisodes = ref({}); // Store visible episodes for each child

// Initialize with first batch of episodes
const initializeEpisodes = () => {
  courseData.value.children.forEach((child) => {
    visibleEpisodes.value[child.id] = child.children.slice(0, episodesPerPage);
  });
};

// Function to load more episodes dynamically
const loadMoreEpisodes = async (event) => {
  courseData.value.children.forEach((child) => {
    const currentLength = visibleEpisodes.value[child.id].length;
    if (currentLength < child.children.length) {
      visibleEpisodes.value[child.id] = child.children.slice(0, currentLength + episodesPerPage);
    }
  });
  event.target.complete();
};

const banner = computed(() => {
  const media = getSmallestSizeImage(courseData.value.media?.filter(m => m.category !== CATEGORY_PODCAST_AUDIO), 1)?.url ?? null;
  return media;
});

const openPlayer = (stream, course, series, tracking, skipAutoplay = false) => {
  let startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);

  if (startAt < 0) {
    return;
  }

  store.dispatch('player/openPlayer', {
    course,
    series,
    company: course.company,
    playlist: course.streams,
    startAt,
    tracking,
    skipAutoplay,
  });
};

const emailTrackingCheck = () => {
  if (route.query.ui) {
    const meta = {
      url: process.env.VUE_APP_BASE_URL + route.fullPath,
      unique_token: route.query.ui,
    };
    trackThis(TRACKING_MAIL_CONTENT_CLICK, meta);
  }
};

const openCompanyDetailsPage = () => {
  router.push({
    name: 'Company',
    params: {
      companyId: courseData.value.company.id,
    },
  });
};

const fetchPublicData = () => {
  // tracking
  trackThis(TRACKING_PUBLIC_COURSE_VIEW, {}, route.params.courseId);

  store.commit('course/refreshCourseData');
  store.commit('player/setPlayerCollapse', true);
  store.dispatch('course/setPublicCourseData', route.params.courseId)
    .then(() => {
      initializeEpisodes();
    }).finally(() => {
      loading.value = false;
    });
};

function findStreamAndEpisode() {
  const { entity_type} = courseData.value;

  if (entity_type === 'series') {
    for (const season of courseData.value.children) {
      for (const episode of season.children) {
        const stream = episode.streams.find(({ id }) => id === +route.query.s);
        if (stream) {
          return { stream, episode };
        }
      }
    }
  }

  if (entity_type === 'episode') {
    const stream = courseData.value.streams.find(({ id }) => id === +route.query.s);
    if (stream) {
      return { stream, episode: courseData.value };
    }
  }

  return null;
}

const fetchPrivateData = () => {
  // tracking
  store.state.course.courseTrackingEnabled
    ? trackThis(TRACKING_COURSE_VIEW, {}, route.params.courseId)
    : store.commit('course/setCourseTrackingState', true);

  store.commit('course/refreshCourseData');
  store.commit('player/setPlayerCollapse', true);
  store.dispatch('course/setCourseData', route.params.courseId)
    .then(() => {
      initializeEpisodes();

      if (route.query.s) {
        const streamData = findStreamAndEpisode();

        if (streamData) {
          const { stream, episode } = streamData;
          const isSeries = courseData.value.entity_type === 'series';
          const series = isSeries ? courseData.value : undefined;

          openPlayer(stream, episode, series);
        }
      }
    }).finally(() => {
      loading.value = false;
    });
};

const fetchCourseData = () => {
  isLogged.value
    ? fetchPrivateData()
    : fetchPublicData();

  emailTrackingCheck();
};
fetchCourseData();
</script>

<style lang="scss" scoped>
@import "src/theme/custom";

.img-wrap {
  @media (min-width: $tablet-breakpoint) {
    margin: 0 50px;
  }

  &:deep(ion-skeleton-text) {
    height: 300px;
    width: 300px;
  }

  &:deep(img) {
    max-height: 300px;
    height: 100%;
    width: 100%;
  }
}

.content-wrap {
  h1 {
    font-size: 54px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 24px;
    font-weight: 300;
  }

  h3 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__podcasts {
    &__title {
      font-size: 36px;
      font-weight: 500;
    }

    &__label {
      font-size: 18px;
      font-weight: 400;
    }

    ion-accordion-group ion-item {
      border-radius: 10px;
    }
  }

  @media (max-width: $tablet-breakpoint) {
    padding: 0 15px;

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 14px;
    }

    h3 {
      font-size: 18px;
    }

    &__actions {
      &:deep(ion-icon) {
        font-size: 18px;
      }
    }

    &__podcasts {
      &__title, &__label {
        font-size: 12px;
      }
    }
  }
}

.disabled-clicks {
  pointer-events: none;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
</style>
